import React, { useEffect, useRef } from 'react';
import { setConfig } from 'react-hot-loader';
import { FrontPage } from '../components/index/front-page';
import { Testimonies } from '../components/index/testimonies';
import { Navbar } from '../components/navbar/navbar';
import { PageWrapper } from '../components/index/helpers';
import { Awards } from '../components/index/awards';
import { Seo } from '../components/seo';

import { Parallax, ParallaxLayer } from 'react-spring/addons';
import nkbmHome from '../../static/images/NkbmHome.png';
import rockwalletHome from '../../static/images/RockwalletHome.png';
import complexHome from '../../static/images/ComplexHome.png';
import takkHome from '../../static/images/TakkHome.png';
import { LetsGetStarted } from '../components/index/lets-get-started';

import './fix-intertia.css';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { LastPage } from '../components/index/last-page';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/layout';
import { NewCaseStudy } from '../components/new-case-study/new-case-study';
import { FEATURE_COLORS, SECONDARY_BG_COLOR } from '../constants';
import { AwardsCarousel } from '../components/awards-carousel/awards-carousel';

setConfig({ pureSFC: true });

export const MAX_PAGES = 10;

const Index = () => {
    const { page, setPage, canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);
    const parallax = useRef();

    // Page switcher
    useEffect(() => {
        if (parallax.current) {
            parallax.current.scrollTo(page);
        }
    }, [page]);

    // Function for changing the bg color depending on the page we are currently at
    const getBgColor = () => {
        if (page < 2 || page > 4) {
            return null;
        } else {
            return null;
        }
    };

    useEffect(() => {
        parallax.current.updateRaf();
    }, []);

    let hideLogo = false;
    if (typeof window !== 'undefined') {
        if (window.innerWidth < 765) {
            hideLogo = true;
        }

        // phone in landscape
        if (window.innerWidth > window.innerHeight && window.innerWidth < 825) {
            hideLogo = true;
        }
    }

    return (
        <Layout withNavigation={false} setCanScroll={setCanScroll} canScroll={canScroll} hideLogo={hideLogo}>
            <PageWrapper bg={getBgColor()}>
                <Helmet>
                    <body className={'fix-inertia'} />
                </Helmet>
                <Seo />
                <Navbar
                    theme={page !== 1 && page !== 6 && page !== 7 ? null : 'dark'}
                    showShadow={page === 0}
                    backToTop={() => setPage(0)}
                    setCanScroll={setCanScroll}
                    canScroll={canScroll}
                />
                <Parallax ref={parallax} pages={MAX_PAGES} scrolling={false} config={{ duration: 200 }}>
                    <ParallaxLayer offset={0} speed={0}>
                        <FrontPage />
                    </ParallaxLayer>
                    <ParallaxLayer offset={1} speed={0}>
                        <Testimonies />
                    </ParallaxLayer>
                    <ParallaxLayer offset={2} speed={0}>
                        <NewCaseStudy
                            color={FEATURE_COLORS.nkbm_red2}
                            image={nkbmHome}
                            title={'NKBM'}
                            tags={['iOS', 'Android', 'Responsive Web app']}
                            url={'/case-studies#banknet'}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer offset={3} speed={0}>
                        <NewCaseStudy
                            color={FEATURE_COLORS.rock_green}
                            image={rockwalletHome}
                            title={'Rock<wbr>Wallet'}
                            tags={['iOS', 'Android', 'Admin Web app']}
                            url={'/case-studies#rockwallet'}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer offset={4} speed={0}>
                        <NewCaseStudy
                            color={FEATURE_COLORS.complex_black}
                            image={complexHome}
                            title={'Complex'}
                            tags={['iOS', 'Android', 'Responsive Web app']}
                            url={'/case-studies#complex'}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer offset={5} speed={0}>
                        <NewCaseStudy color={SECONDARY_BG_COLOR} image={takkHome} seeMore title={'Want to see more?'} />
                    </ParallaxLayer>
                    <ParallaxLayer offset={6} speed={0}>
                        <Awards />
                    </ParallaxLayer>
                    <ParallaxLayer offset={7} speed={0}>
                        <AwardsCarousel />
                    </ParallaxLayer>
                    <ParallaxLayer offset={8} speed={0}>
                        <LetsGetStarted />
                    </ParallaxLayer>
                    <ParallaxLayer offset={9} speed={0}>
                        <LastPage hideLogo={hideLogo} />
                    </ParallaxLayer>
                </Parallax>
            </PageWrapper>
        </Layout>
    );
};

export default Index;
